<template>
  <div class="forum-wraper">
    <ul class="forum-tags">
      <li
        v-for="theme in themeList"
        :class="{active: themeId == theme.themeId}"
        @click="selectTag(theme)"
        :key="theme.themeId"
      >{{theme.themeName}}</li>
    </ul>
    <div class="forum-info">
      <div class="forum-info-container">
        <img :src="themeDetail.picUrl" alt />
        <div class="info-wraper">
          <h1 class="tag-name">
            {{themeDetail.themeName}}
            <span>帖子{{themeDetail.stickNum || 0}}</span>
          </h1>
          <h2>{{themeDetail.themeInfo}}</h2>
        </div>
      </div>
      <div class="operation-container">
        <a
          href="javaScript:void(0)"
          class="kantie"
          :class="{active:isEssence==0}"
          @click="showAll"
        >看帖</a>
        <a
          href="javaScript:void(0)"
          class="jingpin"
          :class="{active: isEssence == 1}"
          @click="showJingpin"
        >精品</a>
        <button class="btn" @click="postStick">+ 发帖</button>
      </div>
    </div>
    <ul class="posts-list">
      <li v-for="item in stickList" :key="item.stickId" @click="toDetail(item)">
        <div class="label-conteainer">
          <div>
            <span class="replay-counts">{{item.replyNum+'条'}}</span>
            <span class="stick" v-if="item.isTop">置顶</span>
            <span class="good" v-if="item.isEssence">精</span>
          </div>
          <div class="user-info">
            <img :src="item.portraitUrl || defaultHead" alt />
            <span>{{item.username}}</span>
            <span>{{item.createTime.split(' ')[0]}}</span>
          </div>
        </div>
        <div class="mt20" v-html="item.stickTitle"></div>
      </li>
    </ul>
    <el-pagination
      background
      layout="prev, pager, next"
      @current-change="handlePageChange"
      :current-page="currentPage"
      :total="totalCount"
      style="margin:10px auto;text-align:center"
    ></el-pagination>
  </div>
</template>

<script>
import { getThemeList, getStickList } from "@/assets/js/api";
export default {
  data() {
    return {
      themeId: 1,
      numbers: 10,
      themeList: [],
      themeDetail: {},
      stickList: [],
      page: 1,
      currentPage: 1,
      limit: 10,
      totalCount: 0,
      isEssence: 0,
      defaultHead: require("../assets/images/moren.png")
    };
  },
  methods: {
    handlePageChange(page) {
      this.page = page;
      this.getStickLists();
    },
    showJingpin() {
      this.isEssence == 1 ? (this.isEssence = 0) : (this.isEssence = 1);
      this.getStickLists();
    },
    showAll() {
      this.isEssence = 0;
      this.getStickLists();
    },
    postStick() {
      sessionStorage.setItem("themeDetail", JSON.stringify(this.themeDetail));
      this.$router.push({
        path: "/stick/post"
      });
    },
    selectTag(theme) {
      this.themeId = theme.themeId;
      this.page = 1;
      this.themeDetail = theme;
      sessionStorage.setItem("themeDetail", JSON.stringify(this.themeDetail));
      sessionStorage.setItem("themeId", theme.themeId);
      this.getStickLists();
    },
    toDetail(stick) {
      sessionStorage.setItem("themeDetail", JSON.stringify(this.themeDetail));
      this.$router.push({
        path: `/forum/detail/${this.themeId}/${stick.stickId}`
      });
    },
    getStickLists() {
      let postData = {
        page: this.page,
        themeId: this.themeId,
        limit: this.limit
      };
      if (this.isEssence == 1) {
        postData.isEssence = this.isEssence;
      }
      getStickList(postData).then(result => {
        this.stickList = result.Page.records;
        this.totalCount = result.Page.total;
        this.currentPage = result.Page.current;
      });
    }
  },
  mounted() {
    getThemeList().then(result => {
      if (result.code == 0) {
        this.themeList = result.list;
        this.themeId = this.$route.params.id;
        this.themeDetail = result.list.find(
          item => item.themeId == this.themeId
        );

        this.getStickLists();
      }
    });
  },
  destroyed() {
    if (window.loading) {
      window.loading.close();
    }
  }
};
</script>

<style lang="scss" scoped>
.forum-wraper {
  width: 1200px;
  min-height: calc(100% - 180px);
  margin: 0 auto;
  .forum-tags {
    height: 60px;
    background-color: #f7f7f7;
    display: flex;
    justify-content: space-around;
    line-height: 60px;
    li {
      list-style: none;
      font-size: 15px;
      cursor: pointer;
      border-bottom: 1px solid transparent;
    }
    li:hover,
    li.active {
      color: #00d59d;
      border-color: #00d59d;
    }
  }
  .forum-info {
    margin-top: 15px;
    height: 199px;
    background: #f7f7f7;
  }
  .forum-info-container {
    padding: 10px 20px;
    display: flex;
    img {
      margin-right: 10px;
      width: 120px;
      height: 100px;
      background: #00d59d;
    }
    .info-wraper {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      line-height: 39px;
      color: #666666;
    }
    .tag-name {
      color: #222222;
      font-size: 18px;
      span {
        color: #666666;
        margin-left: 50px;
        letter-spacing: 1px;
      }
    }
  }
  .operation-container {
    margin-top: 35px;
    color: #999999;
    padding-left: 20px;
    a {
      color: #999999;
    }
    button {
      margin-right: 20px;
      background-color: #00d59d;
      border: none;
      color: #fff;
      float: right;
      line-height: 30px;
      width: 100px;
      border-radius: 3px;
      cursor: pointer;
    }
  }
  .kantie {
    margin-right: 30px;
    background: url("../assets/images/kantie.png") no-repeat;
    padding-left: 26px;
    background-size: 23px;
    background-position: 0;
  }
  .kantie:hover,
  .kantie.active {
    color: #00d59d;
    background: url("../assets/images/kantie-active.png") no-repeat;
    background-size: 23px;
    background-position: 0;
  }
  .jingpin {
    margin-right: 30px;
    background: url("../assets/images/jingpin.png") no-repeat;
    padding-left: 26px;
    background-size: 19px;
    background-position: 0;
  }
  .jingpin:hover,
  .jingpin.active {
    color: #00d59d;
    background: url("../assets/images/jingpin-active.png") no-repeat;
    background-size: 19px;
    background-position: 0;
  }
  .posts-list {
    li {
      list-style: none;
      padding: 20px 10px;
      border-bottom: 1px solid #eeeeee;
      cursor: pointer;
      div:last-child {
        color: #333333;
        font-size: 16px;
      }
    }
  }
  .label-conteainer {
    display: flex;
    justify-content: space-between;
    img {
      height: 24px;
      border-radius: 50%;
      vertical-align: middle;
    }
    span {
      display: inline-block;
      padding: 2px 5px;
      color: #00d59d;
      border: 1px solid;
      border-radius: 3px;
      margin-right: 10px;
      font-size: 12px;
    }
    .sticky {
      color: #3386f7;
    }
    .good {
      color: #ffaa00;
    }
    .user-info {
      span {
        font-size: 13px;
        border: none;
        color: #999999;
        margin-right: 0;
      }
    }
  }
}
</style>